import { GetStaticPaths, GetStaticProps, NextPage } from "next";
import { NextSeo } from "next-seo";
import React from "react";

import {
  HIGH_PRIORITY_SLUGS,
  REVALIDATE_TIME_HIGH_PRIORITY_SECONDS,
  REVALIDATE_TIME_LOW_PRIORITY_SECONDS,
} from "../../../../revalidation";
import ContentfulPage from "../../../components/ContentfulPage";
import ErrorPage from "../../../components/ErrorPage";
import HeadAlternateLinks from "../../../components/HeadAlternateLinks";
import { PageLocaleProvider } from "../../../contexts/PageLocaleContext";
import { ThemeProvider } from "../../../contexts/ThemeContext";
import {
  getPageBySlug,
  getAttractionBySlug,
  EntryPage,
  EntryAttraction,
  isEntryPage,
  EntryRestaurant,
  getRestaurantBySlug,
  getPages,
  getRestaurants,
  getAttractions,
  PageLikeEntry,
} from "../../../lib/contentful";
import { buildSlug } from "../../../lib/contentful/build-entry-link";
import { getPageHrefsBySlug } from "../../../lib/contentful/localization";
import removeCircularReferencesFromEntry from "../../../lib/contentful/remove-circular-references-from-entry";
import { isAttraction, isRestaurant, Locale, locales } from "../../../lib/i18n";
import { getNextSeoConfigForContentfulPage } from "../../../seo/seo";
import { IPageFields } from "../../../types/generated/contentful";

interface PageProps {
  entry?: PageLikeEntry;
  hrefs?: { [key: string]: string };
  notFound?: boolean;
}

const Page: NextPage<PageProps> = ({ entry, hrefs, notFound }) => {
  if (!entry || notFound === true) {
    return <ErrorPage />;
  }

  let theme: IPageFields["theme"] = "sarkanniemi";

  if (isEntryPage(entry)) {
    theme = entry.fields.theme || theme;
  }

  return (
    <ThemeProvider theme={theme}>
      <PageLocaleProvider hrefs={hrefs}>
        <HeadAlternateLinks hrefs={hrefs} />
        <NextSeo {...getNextSeoConfigForContentfulPage(entry)} />
        <ContentfulPage entry={entry} />
      </PageLocaleProvider>
    </ThemeProvider>
  );
};

type URLParams = {
  locale: string;
  slug: string[];
};

const notFound = {
  props: {
    notFound: true,
  },
};

export const getStaticProps: GetStaticProps<PageProps, URLParams> = async ({
  params,
  preview,
}) => {
  try {
    if (params === undefined) {
      return notFound;
    }

    if (locales.includes(params.locale as Locale)) {
      const slug = params.slug[params.slug.length - 1];

      if (!slug || slug.length === 0) {
        return notFound;
      }

      let entry: PageLikeEntry;

      if (isAttraction(params.slug)) {
        entry = await getAttractionBySlug(slug, params.locale, preview);
      } else if (isRestaurant(params.slug)) {
        entry = await getRestaurantBySlug(slug, params.locale, preview);
      } else {
        entry = await getPageBySlug(slug, params.locale, preview);
      }

      const hrefs = await getPageHrefsBySlug(
        params.slug,
        slug,
        params.locale,
        preview,
      );

      const props = {
        entry: removeCircularReferencesFromEntry(entry),
        hrefs,
      };

      return {
        props,
        revalidate: HIGH_PRIORITY_SLUGS.includes(slug)
          ? REVALIDATE_TIME_HIGH_PRIORITY_SECONDS
          : REVALIDATE_TIME_LOW_PRIORITY_SECONDS,
      };
    }
    return notFound;
  } catch (error) {
    return notFound;
  }
};

type Path = {
  params: URLParams;
};

function mapItemToPath(
  item: EntryPage | EntryRestaurant | EntryAttraction,
  locale: string,
): Path {
  return {
    params: {
      locale,
      slug: buildSlug(item),
    },
  };
}

function combinePathArrays(first: Path[], second: Path[]): Path[] {
  return [...first, ...second];
}

export const getStaticPaths: GetStaticPaths = async () => {
  const pagesWithLocale = await Promise.all(
    locales.map(locale => getPages(locale)),
  );
  const restaurantsWithLocale = await Promise.all(
    locales.map(locale => getRestaurants(locale)),
  );
  const attractionsWithLocale = await Promise.all(
    locales.map(locale => getAttractions(locale)),
  );
  const pagePaths = pagesWithLocale
    .map(({ locale, pages }) => {
      return pages.map(page => mapItemToPath(page, locale));
    })
    .reduce(combinePathArrays);
  const restaurantPaths = restaurantsWithLocale
    .map(({ locale, restaurants }) => {
      return restaurants.map(restaurant => mapItemToPath(restaurant, locale));
    })
    .reduce(combinePathArrays);
  const attractionPaths = attractionsWithLocale
    .map(({ locale, attractions }) => {
      return attractions.map(attraction => mapItemToPath(attraction, locale));
    })
    .reduce(combinePathArrays);

  const paths = [...pagePaths, ...restaurantPaths, ...attractionPaths];
  return {
    paths,
    fallback: "blocking",
  };
};

export default Page;
